import Filter                  from "hm-filters/base-filter";
import {MboIndexPossibleValue} from "hm-filters/operators/MboClientIndexOperator";
import { QLFilter }            from "@/graphql/queries/ql/composables";

export interface OperatorData {
	value: any;
	text: string;
	appendText?: string;
}

export type FilterPossibleOperator = string | boolean | number | null;
export type FilterPossibleValue =  number | number[] | string | string[] | boolean  | null;


export interface FilterExportedData {
	value?: FilterPossibleValue ,
	operator?: FilterPossibleOperator,
	field?: string | null
}

export interface QLFilterWithBetterInterface extends QLFilter{
	options:FilterExportedData;
}

export default class Operator implements OperatorData {
	value: FilterPossibleOperator;
	text: string;
	appendText?: string;
	aloneInFilterGroup: boolean = false;
	isDate = false;
	isTime = false;
	hasInput = false;

	constructor(value: FilterPossibleOperator, text: string, appendText ?: string) {
		this.value = value;
		this.text = text;
		this.appendText = appendText;
	}

	_operator: FilterPossibleOperator = "=";

	get operator(): FilterPossibleOperator {
		return this._operator;
	}

	get hasAppendText(): boolean {
		return !!this.appendText;
	}

	get formInputType(): string {
		return "text";
	}

	get type(): string {
		return "Operator";
	}

	get fullType(): string{
		return this.type;
	}

	get caresAboutField(): boolean {
		return true;
	}

	setOperator(op: FilterPossibleOperator) {
		this._operator = op;
		return this;
	}

	setAloneInFilterGroup() {
		this.aloneInFilterGroup = true;
		return this;
	}

	parsesValueBeforeExport(myvalue : any){
		return myvalue;
	}

	getFormattedOutput(options: { value: any }) {
		return options.value;
	}

	transformApiOptionsToValue(options: FilterExportedData) : FilterPossibleValue |FilterExportedData {
		return options.value;
	}

	exportObject(filter: Filter, myvalue: FilterPossibleValue | FilterExportedData): FilterExportedData {
		return {
			field:    filter.options.field,
			operator: this.value,
			value:    <any>myvalue
		};
	}
}

