<template>
  <div>
    <div
        v-if="loading"
        class="p-4 shadow-md rounded-lg bg-white w-full h-40 flex items-center justify-center">
      <loader class="w-8 h-8 text-gray-300" />
    </div>

    <div
        v-if="!loading && sorted_quick_responses.length > 0"
        class="shadow-md rounded-lg bg-white divide-y"
        @dragover.prevent
        @dragenter.prevent>
      <div
          v-for="(quickResponse, index) in sorted_quick_responses"
          :key="quickResponse.id"
          class="py-4"
          draggable="true"
          @dragstart="dragFns.startDrag($event, quickResponse)"
          @dragend="dragFns.endDragging"
          @dragenter="dragFns.onDragEnter(index, true)"
          @dragleave="dragFns.onDragEnter(index, false)"
          @drop="handleDrop(index)">
        <studio-quick-responses-list-item :quick-response="quickResponse" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Loader                                                        from "@/components/layout/Loader.vue";
import StudioQuickResponsesListItem                                  from "@/components/studio/settings/QuickResponses/StudioQuickResponsesListItem.vue";
import { computed, ref, watch }                                      from "vue";
import { useQuickResponseSaveOrderMutation, useQuickResponsesQuery } from "@/graphql/queries/ql/composables";
import dragDropComposable                                            from "@/composables/dragDropComposable";

const { status, dragFns } = dragDropComposable();
const quickResponsesQuery = useQuickResponsesQuery();
const { loading } = quickResponsesQuery;

// Create a ref to store the ordered responses
const orderedResponses = ref([]);
// Call the mutation to save the new order
const { mutate: saveOrder } = useQuickResponseSaveOrderMutation();

// Watch for changes in the query result and update the ordered responses
watch(
    () => quickResponsesQuery.result.value?.quick_responses,
    (newResponses) => {
      if (newResponses) {
        orderedResponses.value = [ ...newResponses ].sort((a, b) => a.order - b.order);
      }
    },
    { immediate: true }
);

const sorted_quick_responses = computed(() => orderedResponses.value);

const handleDrop = async (targetIndex: number) => {
  const draggedId = status.over.itemId;
  if (!draggedId) {
    return;
  }

  const draggedIndex = orderedResponses.value.findIndex(qr => qr.id === draggedId);
  if (draggedIndex === -1 || draggedIndex === targetIndex) {
    return;
  }

  // Create a copy of the array to avoid direct mutation
  const updatedResponses = [ ...orderedResponses.value ];

  // Remove the dragged item
  const [ draggedItem ] = updatedResponses.splice(draggedIndex, 1);

  // Insert it at the new position
  updatedResponses.splice(targetIndex, 0, draggedItem);

  // Update the orderedResponses ref with the new order starting from 0
  orderedResponses.value = updatedResponses.map((qr, index) => ({
    ...qr,
    order: index
  }));


  // Prepare the order update payload
  const orderUpdates = orderedResponses.value.map(qr => ({
    quick_response_id:    qr.id,
    order: qr.order
  }));

  await saveOrder({ items: orderUpdates });
};

</script>
