import CalendarCurrentDateInMonth from "hm-filters/filters/calendarCurrentDateInMonthFilter";
import CalendarCurrentDateInWeek  from "hm-filters/filters/calendarCurrentDateInWeekFilter";
import StudioStartDateFilter      from "hm-filters/filters/StudioStartDateFilter";
import CalendarCurrentMonthInYearFilter from "hm-filters/filters/calendarCurrentMonthInYearFilter";

export const CalendarFilters = [
	// Calendar
	new CalendarCurrentDateInMonth("Day in month", {"field": "day_in_month"}).setDescription(
		"Select the day of the month."),
	new CalendarCurrentDateInWeek("Day in week", {"field": "day_in_week"}).setDescription("Select the day in the week"),
	new CalendarCurrentMonthInYearFilter("Month", {"field": "month_in_year"}).setDescription("Select a specific month of the year"),
	new StudioStartDateFilter("Studio start date", {"field": "start_date"}).setDateFilter().setDescription(
		"Trigger automations based on your studio's start date. Studio Start Date is only used for pre-opening studio sequences.  This setting must be setup by the LoopSpark support team.  If your studio is preparing for a pre-opening launch, please reach out to LoopSpark support (support@loopspark.com) to set your studio’s start date.")
];
