<template>
  <div class="min-h-full">
    <div class="text-xl text-gray-900 font-medium">
      <slot name="title" />
    </div>
    <div class="text-sm text-gray-600">
      <slot name="subtitle" />
    </div>
    <div class="pt-8">
      <div class="lg:flex  justify-between">
        <div class="grow mb-8">
          <select-filter-in-category
              v-if="currentStep === 0"
              :filters="categoryFilters"
              @selected="selectFilter($event)" />

          <select-operator
              v-if="currentStep === 1"
              :filter="filterSelected"
              v-model="operatorSelectedModel"
              @added-rule="addedRule($event)" />
        </div>

        <div class="lg:w-1/3 lg:ml-8 mb-8 text-xs text-gray-600 shadow-inner p-4 rounded shrink-0">
          <slot name="side-description" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>

import Filters                from "hm-filters/filters";
import Filter                 from "hm-filters/base-filter";
import Operator               from "hm-filters/operators/operator";
import SelectFilterInCategory from "@/components/Segment/Edit/Categories/SelectFilterInCategory.vue";
import SelectOperator         from "@/components/Segment/Edit/Categories/SelectOperator.vue";
import { computed, toRef }    from "vue";
import currentTeamQuery       from "@/composables/queries/currentTeamQuery";

interface Props {
  categoryName: string;
  filterSelected: Filter | null;
  operatorSelected: Operator | null;
}

const props = withDefaults(defineProps<Props>(), {
  filterSelected:   null,
  operatorSelected: null,
});

const emit = defineEmits([ "addedRule", "stepBack", "update:filterSelected", "update:operatorSelected" ]);

const { loading, currentTeam } = currentTeamQuery();

const filterSelected = toRef(props, "filterSelected");
const categoryName = toRef(props, "categoryName");

const categoryFilters = computed(() => {
  if (loading.value) {
    return [];
  }

  return Filters.getInstance().byProvider(currentTeam.value.provider)
                .filters
                .filter(f => f.path[0] == categoryName.value)
                .sort((a, b) => {
                  return ([ ...a.path, a.name ].join(".") > ([ ...b.path, b.name ].join("."))) ? 1 : -1;
                }).filter(f => !f.deprecated);
});

const currentStep = computed(() => {
  return !!filterSelected.value ? 1 : 0;
});

const operatorSelectedModel = computed({
                                         get: () => props.operatorSelected,
                                         set: (val) => emit("update:operatorSelected", val)
                                       });

const stepBack = () => {
  if (filterSelected.value) {
    emit("update:filterSelected", null);
    return;
  }
  emit("stepBack");
};

const addedRule = (data) => {
  emit("addedRule", data);
};

const selectFilter = (name) => {
  emit("update:filterSelected", name);
};

</script>
