import CalendarGroup   from "./partials/CalendarGroup";
import NumericOperator from "../operators/NumericOperator";
import MonthInYearOperator from "hm-filters/operators/MonthInYearOperator";

class CalendarCurrentDateInMonth extends CalendarGroup {
	constructor(name: string, options: any) {
		super(name, options);

		this.clearOperators();
		this.addOperators([
			                  new MonthInYearOperator("=", "is"),
			                  new MonthInYearOperator("!=", "is not")
		                  ]);
	}

	get type(): string {
		return "CurrentMonthInYearFilter";
	}
}


export default CalendarCurrentDateInMonth;
