<template>
  <modal :open="openModel" @update:open="val => emit('update:open', val)" title="Edit Quick Response">
    <form @submit.prevent="handleSubmit" class="block w-full">
      <div class="px-4 py-4 space-y-4 w-full">
        <div class="text-xl text-green-700 font-medium">
          Edit quick response
        </div>

        <label class="block">
          <span class="form-label">Title</span>
          <TextInput v-model="form.title"
                     class="mt-1 block w-full"
                     :min-length="3"
                     :max-length="300"
                     required />
        </label>
        <label class="block">
          <span class="form-label">Content</span>
          <TextAreaInput v-model="form.content"
                         class="mt-1 block w-full"
                         :min-length="3"
                         :max-length="300"

                         required />
        </label>
      </div>
      <div class="mt-6 flex justify-end space-x-2">
        <v-button type="button" class="modal-button modal-button-white" variant="secondary" @click="open = false">
          Cancel
        </v-button>
        <v-button type="submit" class="modal-button modal-button-green" :loading="loading">
          Save Changes
        </v-button>
      </div>
    </form>
  </modal>
</template>

<script lang="ts" setup>
import { computed, ref }                                   from "vue";
import Modal                                               from "@/components/layout/Modal.vue";
import VButton                                             from "@/components/layout/VButton.vue";
import TextInput                                           from "@/components/layout/Forms/TextInput.vue";
import TextAreaInput                                       from "@/components/layout/Forms/TextAreaInput.vue";
import { QLQuickResponse, useQuickResponseUpdateMutation } from "@/graphql/queries/ql/composables";
import $toast                                              from "@/composables/toast";

interface Props {
  quickResponse: QLQuickResponse;
  open: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits([ "update:open", "update" ]);

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const loading = ref(false);
const form = ref({
                   title:   props.quickResponse?.title || "",
                   content: props.quickResponse?.content || ""
                 });

const updateMutation = useQuickResponseUpdateMutation({});

updateMutation.onDone(() => {
  loading.value = false;
  openModel.value = false;
  $toast({
           type:         "success",
           message:      "Quick Response updated",
           linkRouteObj: null,
           linkText:     null
         });
  emit("update");
});

updateMutation.onError(() => {
  loading.value = false;
  $toast({
           type:         "error",
           message:      "Failed to update quick response",
           linkRouteObj: null,
           linkText:     null
         });
});

const handleSubmit = () => {
  loading.value = true;
  updateMutation.mutate({
                          id:      props.quickResponse.id,
                          title:   form.value.title,
                          content: form.value.content
                        });
};
</script>
