<template>
  <modal
      v-model:open="openModel"
      :has-close-button="false"
      full-heigth
      full-width
      @close="close">
    <div class="w-full grow h-full">
      <filter-group-recap
          v-if="step === 'recap'"
          v-model="filterGroup"
          @close="close"
          @delete="deleted"
          @edited="editedFilter"
          @new-filter="newFilterBtn" />
      <filter-group-select-category
          v-if="step === 'category'"
          @step-back="stepBack"
          @selected-category="selectedCategory" />
      <component
          :is="getCategoryComponent(newCategory)"
          v-if="step === 'selectFilter'"
          v-model:filter-selected="filterSelected"
          v-model:operator-selected="selectedOperator"
          :category-name="newCategory"
          @step-back="stepBack"
          @added-rule="addedRule" />
    </div>
    <template #buttons>
      <v-button
          v-if="step == 'recap'"
          class="modal-button modal-button-green"
          @click="close">
        Save!
      </v-button>
      <v-button
          v-if="step == 'recap' && hasChanges"
          class="modal-button modal-button-text modal-button-text-loopspark"
          @click="revert">
        Revert changes
      </v-button>

      <v-button
          v-if="step == 'recap'"
          class="modal-button modal-button-text modal-button-text-red"
          @click="deleted">
        Delete
      </v-button>

      <div
          v-if="step != 'recap'"
          class="grow w-full">
        <v-button
            class="modal-button modal-button-white"
            @click="stepBack">
          <div class="inline-flex items-center">
            <svg-icon
                class="w-4 h-4"
                name="left" />
            <span class="">Back</span>
          </div>
        </v-button>
      </div>
    </template>
  </modal>
</template>
<script lang="ts" setup>
import Modal                                        from "@/components/layout/Modal.vue";
import VButton                                      from "@/components/layout/VButton.vue";
import FilterGroupRecap                             from "@/components/Segment/Edit/FilterGroupRecap.vue";
import FilterGroupSelectCategory                    from "@/components/Segment/Edit/FilterGroupSelectCategory.vue";
import FilterGroupCategoryClient                    from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryClient.vue";
import FilterGroupCategoryCalendar                  from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryCalendar.vue";
import FilterGroupCategoryCommunication             from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryCommunication.vue";
import FilterGroupCategoryContract                  from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryContract.vue";
import FilterGroupCategoryPackage                   from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryPackage.vue";
import FilterGroupCategoryProduct                   from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryProduct.vue";
import FilterGroupCategorySale                      from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategorySale.vue";
import FilterGroupCategoryBooking                   from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryBooking.vue";
import FilterGroupCategoryForm                      from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryForm.vue";
import SvgIcon                                      from "@/components/SvgIcon.vue";
import { QLFilter, QLFilterGroup }                  from "@/graphql/queries/ql/composables";
import { computed, onMounted, provide, ref, watch } from "vue";
import Filter                                       from "hm-filters/base-filter";
import Operator                                     from "hm-filters/operators/operator";
import { filterAndSelectedOperatorGetter }          from "hm-filters/filterGetter";
import { deepCopy }                                 from "@/classes/helpers/DeepCopy";

const categoryComponents = {
  Client:        FilterGroupCategoryClient,
  Calendar:      FilterGroupCategoryCalendar,
  Communication: FilterGroupCategoryCommunication,
  Contract:      FilterGroupCategoryContract,
  Package:       FilterGroupCategoryPackage,
  Product:       FilterGroupCategoryProduct,
  Sale:          FilterGroupCategorySale,
  Booking:       FilterGroupCategoryBooking,
  Form:          FilterGroupCategoryForm
};

const getCategoryComponent = (category: string | null) => {
  if (!category) {
    return null;
  }
  return categoryComponents[category as keyof typeof categoryComponents];
};

interface Props {
  modelValue: QLFilterGroup;
  open: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "closed"): void;
  (e: "deleted"): void;
  (e: "update:modelValue", value: QLFilterGroup): void;
  (e: "update:open", value: boolean): void;
}>();

const step = ref<"recap" | "category" | "selectFilter">("recap");
const newCategory = ref<string | null>(null);
const filterSelected = ref<Filter | null>(null);
const selectedOperator = ref<Operator | null>(null);
const isEditing = ref(false);
const editedOriginal = ref<any>(null);
provide("oldData", editedOriginal);
const filterGroup = ref<QLFilterGroup>(props.modelValue);

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const loadLocalFromProp = () => {
  filterGroup.value = deepCopy(props.modelValue);
};

onMounted(() => {
  loadLocalFromProp();
});

watch(() => props.modelValue, loadLocalFromProp);

const lockedInSameCategory = computed(() => {
  return filterGroup.value.operator_is_and && filterGroup.value.filters.length > 0;
});

const alreadySelectedCategory = computed(() => {
  if (lockedInSameCategory.value) {
    return filterAndSelectedOperatorGetter(filterGroup.value.filters[0]).filter.path[0];
  }
  return "";
});

const selectedCategory = (category: string) => {
  newCategory.value = category;
  step.value = "selectFilter";
};

const newFilterBtn = () => {
  editedOriginal.value = null;
  if (lockedInSameCategory.value) {
    selectedCategory(alreadySelectedCategory.value);
  } else {
    step.value = "category";
  }
};

const editedFilter = (filter) => {
  isEditing.value = true;
  newCategory.value = filter.filter.path[0];
  filterSelected.value = filter.filter;
  selectedOperator.value = filter.operator;
  editedOriginal.value = filter.original;
  step.value = "selectFilter";
};

const close = () => {
  openModel.value = false;
  emit("update:modelValue", filterGroup.value);
  emit("closed");
};

const deleted = () => {
  openModel.value = false;
  emit("deleted");
};

const revert = () => {
  loadLocalFromProp();
};

const hasChanges = computed(() => {
  return JSON.stringify(deepCopy(props.modelValue)) !== JSON.stringify(deepCopy(filterGroup.value));
});

const stepBack = () => {
  if (isEditing.value) {
    filterGroup.value.filters.push(editedOriginal.value);
    isEditing.value = false;
    filterSelected.value = null;
    selectedOperator.value = null;
    step.value = "recap";
    return;
  }
  filterSelected.value = null;
  selectedOperator.value = null;
  if (step.value === "category") {
    step.value = "recap";
    return;
  }
  if (step.value === "selectFilter") {
    if (lockedInSameCategory.value) {
      step.value = "recap";
      return;
    }
    step.value = "category";
    newCategory.value = null;
    return;
  }
};

const addedRule = (rule: QLFilter) => {
  filterGroup.value.filters.push(rule);
  newCategory.value = null;
  step.value = "recap";
  isEditing.value = false;
  filterSelected.value = null;
  selectedOperator.value = null;
};
</script>
