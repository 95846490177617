import MultipleChoiceOperator, { MultipleChoiceOption } from "./MultipleChoiceOperator";
import { OperatorTypes }                                from "hm-filters/operators/OperatorTypes";
import { find }                                         from "lodash";

class MonthInYearOperator extends MultipleChoiceOperator {
	multipleChoicesData: MultipleChoiceOption[] = [
		{ value: 1, display: "January" },
		{ value: 2, display: "February" },
		{ value: 3, display: "March" },
		{ value: 4, display: "April" },
		{ value: 5, display: "May" },
		{ value: 6, display: "June" },
		{ value: 7, display: "July" },
		{ value: 8, display: "August" },
		{ value: 9, display: "September" },
		{ value: 10, display: "October" },
		{ value: 11, display: "November" },
		{ value: 12, display: "December" }
	];

	get type(): string {
		return OperatorTypes.MonthInYear;
	}

	getFormattedOutput(options: MultipleChoiceOption) {
		const values = Array.isArray(options.value) ? options.value : [ options.value ];
		return values.map((item) => {
			return this.multipleChoicesData.find((o) => o.value == item)?.display || "";
		}).join(', ');
	}
}

export default MonthInYearOperator;
