<template>
  <div>
    <div class="text-lg font-medium mb-4 border-b pb-1">
      <div class="inline-flex items-center">
        <div
            v-for="path in paths"
            class="pr-1 inline-flex items-center">
          <div>{{ path }}</div>
          <svg-icon
              class="w-4 h-4"
              name="right" />
        </div>
        <div>
          {{ filter.name }}
        </div>
      </div>
      <div class="text-xs text-gray-500">
        {{ filter.description }}
      </div>
    </div>

    <div class="mt-2">
      <form
          v-if="isDateFilter"
          class="py-2 px-2 pr-4 bg-gray-50 rounded-lg"
          @submit.prevent="addDateRule">
        <div class="pb-2">
          <filter-input-with-date
              v-model="dateOperatorData"
              :filter="filter"
              @is-valid="operatorInputIsValid = $event" />
        </div>
        <v-button
            :disabled="!operatorInputIsValid"
            class="btn btn-green text-sm"
            type="submit"
            @click="addDateRule">
          Add
          rule!
        </v-button>
      </form>

      <div
          v-for="operator in filter.operators"
          v-if="!isDateFilter"
          :class="{'selected-operator': modelValue === operator}"
          class="pl-2 py-2"
          role="radiogroup">
        <label class="inline-flex items-center">
          <input
              v-model="selectedOperator"
              :value="operator"
              class="form-radio"
              type="radio">
          <span>
            <span
                :class="{'font-medium': modelValue === operator}"
                class="ml-4">{{
                operator.text
                                                                  }}</span>
              <span
                  v-if="operator.hasAppendText"
                  class="pl-1 text-xs">({{ operator.appendText }})</span>
          </span>
        </label>
        <form
            v-if="modelValue === operator"
            class="ml-6 py-2 px-2 pr-4 bg-gray-50 rounded-lg"
            @submit.prevent="addRule">
          <div
              v-if="modelValue.hasInput"
              class="pb-2">
            <filter-input-with-text
                v-if="operator.type === operatorTypes.Text || operator.type === operatorTypes.Numeric"
                v-model="operatorInputValue"
                :operator="operator"
                @is-valid="operatorInputIsValid = $event" />

            <filter-input-with-multiple-choice
                v-if="operator.type === operatorTypes.MonthInYear"
                v-model="operatorInputValue"
                :operator="operator"
                @is-valid="operatorInputIsValid = $event" />
            <filter-input-with-multiple-text
                v-if="operator.type === operatorTypes.MultipleText"
                v-model="operatorInputValue"
                :operator="operator"
                @is-valid="operatorInputIsValid = $event" />

            <filter-input-with-day-of-week
                v-if="operator.type === operatorTypes.DayOfWeek"
                v-model="operatorInputValue"
                :operator="operator"
                @is-valid="operatorInputIsValid = $event" />

            <filter-input-with-time
                v-if="operator.type === operatorTypes.ExactTime"
                v-model="operatorInputValue"
                :operator="operator"
                @is-valid="operatorInputIsValid = $event" />

            <filter-input-with-multiple-choice
                v-if="operator.type === operatorTypes.MultipleChoice"
                v-model="operatorInputValue"
                :operator="operator"
                @is-valid="operatorInputIsValid = $event" />

            <filter-input-with-location
                v-if="operator.type === operatorTypes.Location"
                v-model="operatorInputValue"
                :operator="operator"
                @is-valid="operatorInputIsValid = $event" />

            <filter-input-with-mbo-index
                v-if="operator.type === operatorTypes.MboClientIndex"
                v-model="operatorInputValue"
                :operator="operator"
                @is-valid="operatorInputIsValid = $event" />

            <filter-input-with-client-tags
                v-if="operator.type === operatorTypes.ClientTag || operator.type === operatorTypes.MultipleClientTag"
                v-model="operatorInputValue"
                :operator="operator"
                @is-valid="operatorInputIsValid = $event" />

            <filter-input-with-graph-q-l
                v-if="operator.type === operatorTypes.GraphQlMultiple || operator.type === operatorTypes.GraphQl"
                v-model="operatorInputValue"
                :operator="operator"
                @is-valid="operatorInputIsValid = $event" />
          </div>

          <v-button
              :disabled="!canAddRule"
              class="btn btn-green text-sm"
              type="submit">
            Add rule!
          </v-button>
        </form>
      </div>
    </div>
  </div>
</template>
<style scoped>
.selected-operator {
  @apply bg-gray-50 rounded-lg;
}
</style>
<script lang="ts" setup>

import Filter                                                        from "hm-filters/base-filter";
import Operator, { FilterExportedData, QLFilterWithBetterInterface } from "hm-filters/operators/operator";
import VButton                                                       from "@/components/layout/VButton.vue";
import { v4 as uuidv4 }                                   from "uuid";
import FilterInputWithText                                from "@/components/Segment/Edit/Categories/Inputs/FilterInputWithText.vue";
import { OperatorTypes }                                  from "hm-filters/operators/OperatorTypes";
import FilterInputWithMultipleChoice                      from "@/components/Segment/Edit/Categories/Inputs/FilterInputWithMultipleChoice.vue";
import FilterInputWithMultipleText                        from "@/components/Segment/Edit/Categories/Inputs/FilterInputWithMultipleText.vue";
import FilterInputWithLocation                            from "@/components/Segment/Edit/Categories/Inputs/FilterInputWithLocation.vue";
import FilterInputWithClientTags                          from "@/components/Segment/Edit/Categories/Inputs/FilterInputWithClientTags.vue";
import FilterInputWithGraphQL                             from "@/components/Segment/Edit/Categories/Inputs/FilterInputWithGraphQL.vue";
import FilterInputWithDate                                from "@/components/Segment/Edit/Categories/Inputs/FilterInputWithDate.vue";
import FilterInputWithMboIndex                            from "@/components/Segment/Edit/Categories/Inputs/FilterInputWithMboIndex.vue";
import FilterInputWithTime                                from "@/components/Segment/Edit/Categories/Inputs/FilterInputWithTime.vue";
import SvgIcon                                            from "@/components/SvgIcon.vue";
import { computed, inject, onMounted, ref, toRef, unref } from "vue";
import { QLFilter }                                       from "@/graphql/queries/ql/composables";
import FilterInputWithDayOfWeek                           from "@/components/Segment/Edit/Categories/Inputs/FilterInputWithDayOfWeek.vue";

interface Props {
  modelValue: Operator | null;
  filter: Filter;
}
const oldData : QLFilterWithBetterInterface|null = inject("oldData");

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits([ "update:modelValue", "addedRule" ]);

const operatorTypes = OperatorTypes;

const operatorInputValue = ref(null);
const operatorInputIsValid = ref(false);
const dateOperatorData = ref(null);

const modelValue = toRef(props, "modelValue");

const isDateFilter = computed(() => {
  return props.filter.isDateFilter;
});

const canAddRule = computed(() => {
  if (!modelValue.value) {
    return false;
  }
  return !(modelValue.value.hasInput && !operatorInputIsValid.value);
});

const addRule = () => {
  if (!canAddRule.value) {
    return;
  }
  let newFilter: QLFilter = {
    type:    props.filter.type,
    uid:     uuidv4(),
    options: {}
  };

  if (modelValue.value.hasInput) {
    newFilter.options = modelValue.value.exportObject(props.filter,
                                                      modelValue.value.parsesValueBeforeExport(operatorInputValue.value));
  } else {
    newFilter.options = modelValue.value.exportObject(props.filter, modelValue.value.value);
  }
  emit("addedRule", newFilter);
};

const paths = computed(() => {
  return props.filter.path.slice(1);
});

const addDateRule = () => {
  if (!operatorInputIsValid.value) {
    return false;
  }
  let newFilter: QLFilter = {
    type:    props.filter.type,
    uid:     uuidv4(),
    options: dateOperatorData.value
  };
  emit("addedRule", newFilter);
};

const selectedOperator = computed({
                                    get: () => props.modelValue,
                                    set: (val) => emit("update:modelValue", val)
                                  });

onMounted(() => {
  if (oldData?.value?.options) {
    operatorInputValue.value = oldData.value.options.value;
    const matchingOperator = props.filter.operators.find(
        op => op.type === oldData.value.options.operator.type
    );
    if (matchingOperator) {
      emit("update:modelValue", matchingOperator);
      return;
    }
  }

  if (!modelValue.value) {
    emit("update:modelValue", props.filter.operators[0]);
  }
});

</script>
