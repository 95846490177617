export enum OperatorTypes {
	Text = "TextOperator",
	Numeric = "NumericOperator",
	ExactDate = "ExactDateOperator",
	ExactTime = "ExactTimeOperator",
	ClientTag = "ClientTagOperator",
	MultipleClientTag = "MultipleClientTagOperator",
	DayOfWeek = "DayOfWeekOperator",
	MonthInYear = "MonthInYearOperator",
	FixedValue = "FixedValueOperator",
	GraphQl = "GraphQlOperator",
	GraphQlMultiple = "GraphQlMultipleOperator",
	Location = "LocationOperator",
	MboClientIndex = "MboClientIndexOperator",
	MultipleChoice = "MultipleChoiceOperator",
	MultipleText = "MultipleTextOperator",
}

export enum OperatorTypesFullName {
	ClassNameQlOperator = "ClassNameQlOperator",
	AppointmentNameQlOperator = "AppointmentNameQlOperator",
	StaffNameQlOperator = "StaffNameQlOperator",
	AppointmentPackageUsedQlOperator = "AppointmentPackageUsedQlOperator",
	CommunicationReceivedQlOperator = "CommunicationReceivedQlOperator",
	CommunicationCategoryQlOperator = "CommunicationCategoryQlOperator",
	ContractNameQlOperator = "ContractNameQlOperator",
	PackageNameQlOperator = "PackageNameQlOperator",
	ProductTypeQlOperator = "ProductTypeQlOperator",
	FormCategoryQlOperator = "FormCategoryQlOperator",
	FormNameQlOperator = "FormNameQlOperator",
}
